import React from "react";


const Paragraph = ({ children }: {children: React.ReactNode}) => (
    <p className="mt-4 text-base leading-relaxed">
        {children}
    </p>
);

const BoldTitle = ({ text }:{ text:string }) => (
    <span className="font-bold text-lg">
        {text}
    </span>
);


const AvatarCreation = () => {
    
    return (
        <section className="relative py-24 sm:py-32">
            <div className="container">
                <h2 className='text-4xl font-bold tracking-tight'>
                    Avatar Creation
                </h2>
                <Paragraph>
                    Digiternity’s proprietary AI avatar creation technology allows anyone to create ultra-realistic, life-like digital avatars in just a few simple steps.
                </Paragraph>

                <div className="mt-10">
                    <Paragraph>
                        <BoldTitle text="■ Create avatars of anyone of your choice:" /> 
                        &nbsp;Digiternity avatars can be created for a wide range of subjects, including celebrities, influencers, friends, family members, historical figures, fictional or animated characters, and even the creators themselves. All creations are subject to compliance with applicable laws, regulations, and protocols.
                    </Paragraph>

                    <Paragraph>
                        <BoldTitle text="■ Anyone can create:" /> 
                        &nbsp;The Digiternity platform welcomes everyone to create, contribute, and inspire as part of the community.
                    </Paragraph>

                    <Paragraph>
                        <BoldTitle text="■ Input:" /> 
                        &nbsp;Avatars can be generated using multimodal inputs such as video clips, audio recordings, and text prompts, either independently or in combination.
                    </Paragraph>

                    <Paragraph>
                        <BoldTitle text="■ Output:" /> 
                        &nbsp;The result is ultra-realistic digital avatars capable of real-time, human-like interactions through various communication media, including video, voice calls, and text chats.
                    </Paragraph>

                    <Paragraph>
                        <BoldTitle text="■ Copyright and Profit Sharing:" /> 
                        &nbsp;Intellectual property owners of these avatars will receive a portion of the avatar’s lifetime revenue through smart contracts on a blockchain.
                    </Paragraph>
                </div>
            </div>
        </section>
    );
};

export { AvatarCreation };
