import React from "react";


const Paragraph = ({ children }: {children: React.ReactNode}) => (
    <p className="mt-4 text-base leading-relaxed">
        {children}
    </p>
);

const BoldTitle = ({ text }:{ text:string }) => (
    <span className="font-bold text-lg">
        {text}
    </span>
);


const AvatarChat = () => {
    
    return (
        <section className="relative py-24 sm:py-32 overflow-hidden min-h-screen flex flex-col">
            {/* Video background */}
            <video
                className="absolute top-0 left-0 w-full h-full object-cover z-0"
                autoPlay
                loop
                muted
                playsInline
            >
                <source src="/videos/vr-chat.mp4" type="video/mp4" />
            </video>
            
            {/* Dark overlay */}
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70 z-10"></div>
            
            {/* Content */}
            <div className="container relative z-20 text-white flex flex-col justify-center flex-grow">
                <h2 className='text-4xl font-bold tracking-tight'>
                    Avatar Chat
                </h2>
                <Paragraph>
                    Avatar Chat – Talk with the people you have always wanted to but haven’t been able to! <br />
                    <span className="font-bold">
                        ANYONE! ANYTIME! ANYWHERE! 
                    </span>
                </Paragraph>

                <div className="mt-10">
                    <Paragraph>
                        <BoldTitle text="■ Real time" /> 
                        &nbsp;Multimodal humanized and empathetic conversations.
                    </Paragraph>

                    <Paragraph>
                        ■ Digital avatars with <BoldTitle text="ultra-realistic" />  appearances, voices, and personalities.
                    </Paragraph>

                    <Paragraph>
                        <BoldTitle text="■ Multimodal interactions " /> 
                        – video and voice calls, and text chat.
                    </Paragraph>

                    <Paragraph>
                        ■ Singing and dancing &nbsp;
                        <BoldTitle text="at your request." /> 
                    </Paragraph>

                    <Paragraph>
                        ■ The more you chat, <BoldTitle text="the better the avatar" />  will know you, understand you, and be connected to you.
                    </Paragraph>

                    <Paragraph>                    
                        ■ Privacy and Security: All interactions are encrypted, ensuring 
                        <BoldTitle text=" the highest level of security and privacy." />  
                    </Paragraph>
                </div>
            </div>
        </section>
    );
};

export { AvatarChat };
