import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Helmet } from "react-helmet-async";

import { ContributorsSection } from "./sections/contributors";
import { FAQSection } from "./sections/faq";
import { FeaturesSection } from "./sections/features";
import { HeroSection } from "./sections/hero";
import { SupportSection } from "./sections/support";
import { TemplatesSection } from "./sections/templates";
import { TestimonialsSection } from "./sections/testimonials";
import { OurVision } from "./sections/vision";
import { AvatarCreation } from "./sections/avatar-creation";
import { AvatarChat } from "./sections/avatar-chat";
import { Blockchain } from "./sections/blockchain";
import { UseCases } from "./sections/use-case";
import { useEffect } from "react";


export const HomePage = () => {
  const { i18n } = useLingui();
  useEffect(() => {
    // Prevent automatic scrolling on page load
    if (window.location.hash) {
      window.scrollTo(0, 0);
      // Optional: remove the hash from the URL
      history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  }, []);
  return (
    <main className="relative isolate bg-background overflow-x-hidden">
      <Helmet prioritizeSeoTags>
        <html lang={i18n.locale} />
        <title>
          {t`Digiternity.AI`} | {t`Real-time VIDEO CHAT with AI powered HYPER-REALISTIC DIGITAL AVATARS of ANYONE at ANYTIME and ANYWHERE.`}
        </title>
        <meta
          name="description"
          content="Transcending time, space, and social boundaries to connect with your favorite celebrities, KOLs and influencers, historical icons, anime characters, families and friends, or even the digital replica of yourself."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>

      <div className="w-full max-w-[100vw]">
        <HeroSection />
        <OurVision />
        <AvatarCreation />
        <AvatarChat />
        <Blockchain />
        <UseCases />
      {/* <LogoCloudSection /> */}
      {/* <StatisticsSection /> */}
      {/* <FeaturesSection />
      <TemplatesSection />
      <TestimonialsSection />
      <SupportSection />
      <FAQSection />
      <ContributorsSection /> */}
      </div>
    </main>
  );
};
