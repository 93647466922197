import { t } from "@lingui/macro";
import { Separator } from "@reactive-resume/ui";
import { Link } from "react-router-dom";
import { Copyright } from "@/client/components/copyright";
import { LocaleSwitch } from "@/client/components/locale-switch";
import { Logo } from "@/client/components/logo";
import { ThemeSwitch } from "@/client/components/theme-switch";
import {
  TwitterLogo,
  DiscordLogo,
  YoutubeLogo,
  InstagramLogo,
  TiktokLogo,
  TelegramLogo,
} from "@phosphor-icons/react";

export const Footer = () => (
  <footer className="bg-background relative">
    <Separator />

    <div className="container py-12">
      <div className="text-right flex flex-wrap gap-5 justify-start items-center">
        <Link to="/" className="block text-sm font-medium hover:opacity-75 duration-300 transition-opacity" >
          {t`Home`}
        </Link>
        <Link to="/about" className="block text-sm font-medium hover:opacity-75 duration-300 transition-opacity" >
          {t`About US`}
        </Link>
        {/* <Link to="/whitepaper" className="block text-sm font-medium hover:opacity-75 duration-300 transition-opacity" >
          {t`White Pater`}
        </Link> */}
        <Link to="/privacy-policy" className="block text-sm font-medium hover:opacity-75 duration-300 transition-opacity" >
          {t`Privacy Policy`}
        </Link>
        {/* <Link to="/terms" className="block text-sm font-medium hover:opacity-75 duration-300 transition-opacity" >
          {t`Terms of Service`}
        </Link> */}
        <Link to="/faq" className="block text-sm font-medium hover:opacity-75 duration-300 transition-opacity" >
          {t`FAQS`}
        </Link>
        {/* <Link to="/support" className="block text-sm font-medium hover:opacity-75 duration-300 transition-opacity" >
          {t`Support`}
        </Link>
        <Link to="/career" className="block text-sm font-medium hover:opacity-75 duration-300 transition-opacity" >
          {t`Career`}
        </Link> */}
      </div>
      <div className="flex justify-between items-start">
        <div className="flex flex-col gap-y-2">
          {/* <Logo size={140} className="-ml-2" /> */}
          <div className="flex justify-start items-center gap-4 mt-4">
            <Link
              to="/#"
              className="block font-medium hover:opacity-75 duration-300 transition-opacity"
            >
              <YoutubeLogo className="text-2xl" />
            </Link>
            <Link
              to="/#"
              className="block font-medium hover:opacity-75 duration-300 transition-opacity"
            >
              <InstagramLogo className="text-2xl" />
            </Link>
            <Link
              to="/#"
              className="block font-medium hover:opacity-75 duration-300 transition-opacity"
            >
              <TwitterLogo className="text-2xl" />
            </Link>
            <Link
              to="https://discord.gg/ZMqYH88av4"
              className="block font-medium hover:opacity-75 duration-300 transition-opacity"
            >
              <DiscordLogo className="text-2xl" />
            </Link>
          </div>
          <p className="prose prose-sm prose-zinc leading-relaxed opacity-60 dark:prose-invert mt-4">
            {t`Transcending time, space, and social boundaries to connect with your favorite celebrities, KOLs and influencers, historical icons, anime characters, families and friends, or even the digital replica of yourself.`}
          </p>

          <p className="prose prose-sm prose-zinc leading-relaxed opacity-60 dark:prose-invert mt-4">
            {`Ushering in a new era of ubiquitous human-AI collaborations and interactions, achieving digital eternity for every individual and the entire humankind with a virtual existence forever part of humanity's evolving narrative.`}
          </p>
          <Copyright className="mt-6" />
        </div>

        <div className="flex flex-col items-end">
          <div className="lg:space-x-2">
            <LocaleSwitch />
            <ThemeSwitch />
          </div>
        </div>
      </div>
    </div>
  </footer>
);
