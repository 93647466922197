import React from 'react';

const OurVision = () => {
    return (
        <section className="relative bg-secondary-accent overflow-hidden min-h-screen flex flex-col">
            <div className="flex flex-col lg:flex-row flex-grow">
                <div className="lg:w-[55%] relative h-[500px] lg:h-auto flex-grow"> {/* Set to 55% width */}
                    <video 
                        className="absolute top-0 left-0 w-full h-full object-cover"
                        autoPlay 
                        loop 
                        muted 
                        playsInline
                    >
                        <source src="/videos/cosmic.webm" type="video/webm" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="absolute top-0 -right-10 bottom-0 w-40 bg-secondary-accent transform skew-x-6 origin-top-right hidden lg:block"></div>
                </div>
                <div className="lg:w-[45%] py-24 sm:py-32 px-4 sm:px-6 lg:px-8 relative z-10 flex flex-col justify-center"> {/* Set to 45% width */}
                    <div className="max-w-2xl mx-auto lg:mx-0">
                        <h2 className='text-4xl font-bold tracking-tight'>
                            Our Vision
                        </h2>
                        <div className='mt-10 text-base leading-relaxed'>
                            <p className='my-3'>
                                Developing the world's first decentralized AI avatar social media platform and community.
                            </p>
                            <p className='my-3'>
                                The AGI era of the digital age of human civilization is on the horizon and is rapidly approaching. 
                            </p>
                            <p className='my-3'>
                                Our mission is to utilize cutting-edge advancements to empower every individual with the AI avatar technology and transform every individual's lifestyle into the AI Avatar age.
                            </p>
                            <p className='my-3'>
                                Join us in bringing to the world a new era of human-AI collaboration and redefining life and lifestyle for every human being.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export { OurVision }