import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import { Logo } from "@/client/components/logo";
import { ThemeSwitch } from "@/client/components/theme-switch";

// import { DonationBanner } from "./donation-banner";

export const Header = () => (
  <motion.header
    className="fixed inset-x-0 top-0 z-20"
    initial={{ opacity: 0, x: -10, y: -50 }}
    animate={{ opacity: 1, x: -10, y: 0, transition: { delay: 0.3, duration: 0.3 } }}
  >
    {/* <DonationBanner /> */}

    <div className="bg-gradient-to-b from-background to-transparent py-6">
      <div className="container flex items-center justify-between">
        <Link to="/">
          <Logo size={220} />
        </Link>

        <div className="flex items-center">
          <ThemeSwitch />
        </div>
      </div>
    </div>
  </motion.header>
);
