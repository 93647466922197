/* eslint-disable lingui/no-unlocalized-strings */

import { t } from "@lingui/macro";
import { Helmet } from "react-helmet-async";

export const PrivacyPolicyPage = () => (
  <main className="relative isolate bg-background">
    <Helmet prioritizeSeoTags>
      <title>
        {t`Privacy Policy`} - {t`Digiternity.AI`}
      </title>

      <meta
        name="description"
        content="Transcending time, space, and social boundaries to connect with your favorite celebrities, KOLs and influencers, historical icons, anime characters, families and friends, or even the digital replica of yourself."
      />
    </Helmet>

    <section
      id="privacy-policy"
      className="container prose prose-zinc relative max-w-4xl py-32 dark:prose-invert"
    >
      <h1 className="mb-4">{t`Privacy Policy`}</h1>
      <h6 className="text-sm">Last updated on October 8th, 2024</h6>

      <hr className="my-6" />
      <p>
        At Digiternity, we are committed to protecting the privacy feof our platform users to the highest standard possible. This privacy policy (the “Privacy Policy” or “Policy”) outlines how Digiternity Inc. and all of its affiliates (“Digiternity.AI”, “Digiternity”, “we”, “us”, and/or “our”) collects, uses, stores, shares, and protects the personal information and data we collect and process from and about you.
      </p>
      <p>
        Except as described in this Privacy Policy, the Policy applies to any and all websites, mobile applications, and any other electronic and/or digital products and/or other services that are made available by Digiternity.AI and that link to this Policy, and our offline services (collectively, the “Services”).
      </p>
      <p>
        By using any of our Services, you are agreeing to the practices described in this Policy. If you do not agree to the practices described in this Policy, please do not access or use the Services.
      </p>

      <ol>
        <li>
          <h2 className="mb-2">Scope of this Privacy Policy</h2>
          <p>
            This Privacy Policy applies only to our collection, use, sharing, storage, and retention of information about users of the Services, including individuals who access and use the Services on behalf of a business customer.
          </p>
          <p>
            This Privacy Policy does not extend to any websites or platforms operated by third parties that are linked to our Site (whether we provide those links or other users share them) nor does it apply to content, data, applications or materials from third parties, including other users. We are not responsible for the privacy or security of, or information found on, these sites or platforms, or the accuracy, completeness or reliability of third-party materials, and have no control over how your information is collected, stored, or used by other websites. We advise you to check the privacy policies of any third party website or platform before providing any information to them. Our inclusion of any links to third party websites or platforms does not, by itself, imply any endorsement of the content on such platforms or of their owners or operators.
          </p>
        </li>

        <li>
          <h2 className="mb-2">What Information Do We Collect</h2>
          <p>
            When you access or otherwise use our Services, we may collect information from you. The types of information we collect depend on how you use our Services. Please note that we need certain types of information to provide the Services to you. If you do not provide us with such information, or if you ask us to delete that information, you may no longer be able to access or use certain Services. The information we collect may include data you directly provide to us, data we obtain automatically from your interactions with our Services, and data we obtain from other sources.
          </p>
          <p className="underline">
            Information You Provide to Us
          </p>
          <p>
            We may collect information directly from you. For example, you may provide us with information when you use the Services, communicate with us, register for an account, set up your web3 wallet, create avatars, interact with avatars, subscribe to newsletters. Information you provide directly to us may concern you and others and may include, but is not limited to:
          </p>

          <ul>
            <li>
              <span className="font-bold">Account information.</span>
              This includes your name, email address, and password. If you choose to log in using another service, such as Google or Apple, we receive information about the service you used to log in and - depending on your chosen account settings with Google and Apple - details about you, including your name, email address, or unique user identifiers.
            </li>
            <li>
              <span className="font-bold">Profile information.</span>
              We ask you to provide certain profile required information when you set up your profile.
            </li>
            <li>
              <span className="font-bold">User Input.</span>
              When you use our Services, we collect personal information that is included in the text, voice, scripts, images, videos and other Input that you provide us to generate avatars, videos, and other forms of output, alongside metadata associated with the Input. As described below, we may use Input that you provide us to improve our services, for example, to train and enhance the models that power our Services. Some of the features of the Services require us to process parts of faces or bodies within a video or photo, and this may include face imagery.
            </li>
            <li>
              <span className="font-bold">Information posted to the Services.</span>
              Certain features on the Services will enable you to share User Generated Content such as making your avatars, videos and other content available to other users. We collect information that you choose to share or make available (“UGC”), and we, or others, may store, display, reproduce, publish or otherwise use UGC (including with your name and email address) and may or may not attribute it to you. Others, including other users, may also have access to UGC and may have the ability to share it with third parties.
            </li>
            <li>
              <span className="font-bold">Content of Your interactions with our Services.</span>
              This includes all content of your interactions with avatars on Digiternity’s platforms, such as facts you may provide about you or your life, and any photos, videos, and voice and text messages you provide.
            </li>
            <li>
              <span className="font-bold">Interests and preferences.</span>
              You may select conversation preferences, such as topics you would like to discuss, and communication preferences, such as the times of day you like to use our Services. We also learn about your interests and your preferences over time through your use of the Services to personalize your avatar interactions and the features of our Services.
            </li>
            <li>
              <span className="font-bold">Payments, transactions, web3 wallet.</span>
              When you deposit funds into your Digiternity wallet using fiat currency, our third-party payment processor will collect your payment information. And when you engage in transactional activities using crypto currencies and tokens, our blockchain will record all of your transactional activities. We maintain a record of all your transactional activities.
            </li>
          </ul>
          <p>
            You are not required to provide us with such information, but certain features of the Services may not be accessible or available absent the provision of the required information.
          </p>
          <p className="underline">
            Information we collect automatically
          </p>
          <p>
            We and our third-party vendors may use cookies, web beacons, and other tracking technologies to collect information about the computers or devices (including mobile devices) you use to access the Services. As described further below, we may collect and analyze information including but not limited to (a) browser type; (b) ISP or operating system; (c) domain name; (d) access time; (e) referring or exit pages; (f) page views; (g) IP address; (h) unique device identifiers (e.g. IDFA or Android ID); (i) version of our Services you’re using; and (j) the type of device that you use.
          </p>
          <p>
            We may also track when and how frequently you access or use the Services, including how you engage with or navigate our site or mobile application. We may use this information (including the information collected by our third-party vendors) for analytics (including to determine which portions of the Services are used most frequently and what our users like/do not like), to facilitate our ongoing operations, and as otherwise described in this Policy.
          </p>
          <p>
            We and our third-party vendors may use cookies, clear GIFs, pixel tags, and other technologies that help us better understand user behavior, personalize preferences, perform research and analytics, and improve the Services. These technologies, for example, may allow us to tailor the Services to your needs, save your password in password-protected areas, track the avatars and Digiternity website pages you visit, help us manage content, and compile statistics about usage of our Services. We or our third-party vendors also may use certain these technologies in emails to our customers including our creators, users, and copyright owners, to help us track email response rates, identify when our emails are viewed, and track whether our emails are forwarded.
          </p>
          <p>
            Most web browsers automatically accept cookies, but your browser may allow you to modify your browser settings to decline cookies if you prefer. If you disable cookies, you may be prevented from taking full advantage of the Services, because the Services may not function properly. As we adopt additional technologies, we may also gather additional information through other methods. When you use the Services, we may collect general location information (such as general location inferred from an IP address).
          </p>
          <p className="underline">
            Information we collect from other third party sources.
          </p>
          <p>
            We may also collect information when you connect to your Digiternity account using an account maintained by a third party, such as a social media account (“Third-Party Account”). The Services may collect information about you from your Third-Party Accounts in accordance with your permissions. When you connect to us through a Third-Party Account like Facebook or Google, we receive information from that third party identifying your account. We collect and store this information and use it to help you connect to the Services. Connecting your account to a Third-Party Account is completely optional, and you will have the opportunity to grant permission when you attempt to connect. You can revoke permission by logging into the Third-Party Account and disconnecting Digiternity.AI from there, and through the native applications on your smartphone. We may retain the information we collected previously from you.
          </p>
        </li>

        <li>
          <h2 className="mb-2">How We Use the Information We Collect and For What Purpose</h2>
          <p className="underline">
            We may use your information for any of the following purposes:
          </p>
          <ul>
            <li>
              Providing and managing your Account, and access to and use of our Services. It is necessary to perform our obligations under the services agreement between you and us;
            </li>
            <li>
              Personalizing and tailoring your experience with our Services, including surfacing recommendations, enabling you to create Output and generating such output for you. It is necessary to perform our obligations under the services agreement between you and us;
            </li>
            <li>
              Communicate with you, including to inform you about features or aspects of the Services we believe might be of interest to you, or to communicate with you about changes to our terms, conditions, or policies;
            </li>
            <li>
              Engage in other communications with you, such as to respond to your inquiries, comments, feedback, or questions;
            </li>
            <li>
              Analyze, maintain, improve, modify, customize, and measure the Services, including to train our artificial intelligence/machine learning models;
            </li>
            <li>
              Understanding the usage of our services, to understand trends and preferences, to improve and repair errors in the services, and to develop new products, services, features, and functionalities;
            </li>
            <li>
              Enhancing the safety and security of our Services, such as conducting troubleshooting, data analysis, testing, evaluation and system reporting, detect and prevent fraud, criminal activity, or misuses of our Service, and to ensure the security of our IT systems, architecture, and networks;
            </li>
            <li>
              Complying with legal obligations and legal process and to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or other parties, including to enforce our Terms of Service and any other agreements; and
            </li>
            <li>
              Carrying out any other purpose for which the information was collected.
            </li>
          </ul>
          <p>
            We may combine information that we collect from you through the Services with information that we obtain from other sources. We may also aggregate and/or de-identify information collected through the Services. We may use and disclose de-identified or aggregated data for any purpose, including without limitation for research and marketing purposes.
          </p>
          <p className="underline">
            Sensitive information.
          </p>
          <p>
            The Services allow you to input information that may be sensitive and subject to special protections under applicable laws. In your conversations with your AI avatars, you may choose to provide information about your religious views, sexual orientation, political views, health, racial or ethnic origin, philosophical beliefs, or trade union membership. By providing sensitive information, you consent to our use of it for the purposes set out in this Privacy Policy. Note, however, that we will not use your sensitive information – or any content of your interactions with avatars at Digiternity.AI -- for marketing or advertising.
          </p>
        </li>

        <li>
          <h2 className="mb-2">How We Share the Information We Collect</h2>
          <p>
            When circumstances require, we may disclose your personal information to third parties for the purposes described in this Privacy Policy, including:
          </p>
          <ul>
            <li>
              <span className="italic">Affiliates</span>
              : We may disclose information to our affiliates, meaning an entity that controls, is controlled by, or is under common control with Digiternity.AI. Our affiliates may use the information we disclose in a manner consistent with this Policy.
            </li>
            <li>
              <span className="italic">Vendors</span>
              : We may disclose your information to employees, consultants, and other vendors who need access to such information to carry out work or perform services on our behalf, such as those who provide data storage, payment, technology support and services, customer service, analytics, fraud prevention, legal services, and marketing services.
            </li>
            <li>
              <span className="italic">Safety and Protection of Digiternity.AI and Others</span>
              : We may disclose certain information if we believe in good faith that doing so is necessary or appropriate to (i) protect or defend the Digiternity.AI or other parties, including to defend or enforce this Policy, our Terms of Service, or any other contractual arrangement; and/or (ii) protect the rights, property or personal safety of Digiternity.AI, our agents and affiliates, our employees, users and/or the public.
            </li>
            <li>
              <span className="italic">Advertising and Analytics</span>
              : Though we do not engage in advertising as of the date of this Policy, we may in the future disclose or make available some of your information with advertising and analytics partners to serve advertisements on our behalf across the internet and to provide analytics services. These entities may use cookies and tracking technologies to allow us to, among other things, track and analyze data, determine the popularity of certain content, deliver advertising and content targeted to your interests and better understand your online activity. For more information about how to manage having your web browsing information used for advertising purposes, please see the Online Analytics and Tailored Advertising section below.
            </li>
            <li>
              <span className="italic">Legal Requirements.</span>
              We may disclose certain information if we believe in good faith that doing so is necessary or appropriate to comply with any law enforcement, legal, or regulatory process, such as to respond to a warrant, subpoena, court order, or other applicable laws and regulations.
            </li>
            <li>
              <span className="italic">Business Transfers.</span>
              We may disclose certain information, in connection with or during negotiations or closing of any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
            </li>
            <li>
              <span className="italic">Your User Content.</span>
              Certain actions you take may be visible to other users of the Services or result in content that is made available to other users of the Services. For example, if you engage in a Group Chat, you are directing us to make your chats available to other members of that Group Chat, which may include avatars on the Services and other users.
            </li>
            <li>
              <span className="italic">Other Disclosures with Consent or at Your Direction.</span>
              We may disclose your information to nonaffiliated third parties based on your consent to do so. For example, you may direct us to disclose information about you when you refer us or our Services to your friends or other acquaintances.
            </li>
          </ul>
        </li>

        <li>
          <h2 className="mb-2">What Happens When the Ownership of Our Business Changes</h2>
          <p>
            We may, from time to time, expand or reduce our business, and this may involve the sale and/or the transfer of control of all or part of our business, which could take various forms, such as an asset sale, merger, bankruptcy or other business transaction. Personal information provided by users will, where it is relevant to any part of our business so transferred, be transferred along with that part, and the new owner or newly controlled party will, under the terms of this Privacy Policy, be permitted to use the information for the purposes for which it was originally collected by us. We may also disclose personal information to third parties assisting with such a business transaction, such as legal advisors involved in the due diligence process. If any of your personal information will be transferred in such a manner, you will be contacted in advance and informed of the changes.
          </p>
        </li>

        <li>
          <h2 className="mb-2">How we secure your information</h2>
          <p>
            Data security is of great importance to us, and to protect your personal information, we have put in place suitable physical, electronic, and managerial procedures designed to safeguard and secure personal information collected through our Site.
          </p>
          <p>
            Notwithstanding any security measures that we take, it is important to remember that the transmission of data via the Internet may not be completely secure, and we cannot guarantee that the collection, transmission and storage of data will always be secure. Please take suitable precautions when transmitting data via the Internet to us.
          </p>
          <p>
            In addition, if you choose to register an account with us, you are responsible for keeping your account credentials safe. We recommend that you do not share your access details with anyone. If you believe your account has been compromised, please contact us immediately.
          </p>
          <p>
            We use a variety of industry-standard security technologies and procedures to help protect your data from unauthorized access, use, or disclosure.
          </p>
          <p>
            Your account is protected by a password for your privacy and security. You must prevent unauthorized access to your account and personal information by selecting and protecting your password appropriately and limiting access to your computer or device and browser by signing off after you have finished accessing your account.
          </p>
          <p>
            All transmitted data are encrypted during transmission. We use standard Secure Socket Layer (SSL) encryption that encodes information for such transmissions. All stored data are maintained on secure servers. Access to stored data is protected by multi-layered security controls, including firewalls, role-based access controls, and passwords.
          </p>
          <p>
            While we use reasonable commercial efforts to protect the data, no technology, data transmission, or system can be guaranteed to be 100% secure. In the event of a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to your data, we will notify you as soon as we spot the issue.
          </p>
        </li>

        <li>
          <h2 className="mb-2">Blockchain and Decentralized Data</h2>
          <p>
          Digiternity.AI operates on a decentralized network. While this enhances security and transparency, please be aware that information stored on the blockchain is immutable and publicly accessible. We advise users to exercise caution when sharing sensitive information through blockchain transactions.
          </p>
        </li>

        <li>
          <h2 className="mb-2">Data Retention</h2>
          <p>
          We will retain your personal information for only as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
          </p>
          <p>
          To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.
          </p>
          <p>
          If you submit a request to delete your information, we strive to take steps to delete that information within 30 days of your request, unless we are required or permitted to retain such information under applicable law.
          </p>
          <p>
          Due to the nature of blockchain technology, certain data may be retained indefinitely on the decentralized network. However, personal data not stored on the blockchain can be managed through your user dashboard. You have the right to access, correct, or delete this data at any time.
          </p>
        </li>

        <li>
          <h2 className="mb-2">Your Rights</h2>
          <p>
          When you submit information via our Site, you may be given options to restrict our use of your information. We aim to give you strong control on our use of your information. Depending on where you live, you may have certain rights in relation to your personal information. However, these rights are not absolute, and may only apply in certain circumstances.
          </p>
          <ul>
            <li>
              <span className="underline">Access.</span>
              You may have the right to request access to the information we hold about you, how we use it, and who we share it with.
            </li>
            <li>
              <span className="underline">Delete.</span>
              You may have the right to request that we delete information we hold about you.
            </li>
            <li>
              <span className="underline">Correct.</span>
              You may have the right to request that we correct inaccurate information we maintain about you.
            </li>
            <li>
              <span className="underline">Opt out of targeted advertising.</span>
              You may have a right to opt-out of the processing of your information for the purposes of targeted advertising.
            </li>
            <li>
              <span className="underline">Portability.</span>
              You may have the right to receive a copy of personal information we hold about you and request that we transfer it to a third party.
            </li>
            <li>
              <span className="underline">Restriction of processing to storage only.</span>
              You may have the right to ask us to stop, suspend or restrict our processing of personal information.
            </li>
            <li>
              <span className="underline">Objection.</span>
              You may have the right to object to our processing of personal information, including to object to (i.e., opt out of) your information being used to train our models. You can also object to marketing at any time by using the unsubscribe/opt-out function displayed in our communications to you.
            </li>
            <li>
              <span className="underline">Withdrawal of consent.</span>
              Where we rely on consent to process your personal information, you may have the right to withdraw this consent at any time. If you confirm that you wish to withdraw your consent, we will delete your information from our systems. However, you acknowledge this may limit our ability to provide you with the best possible products and services.
            </li>
          </ul>
          <p>
          To submit a request to exercise any of the foregoing rights, please contact us using the details set out in the “Contacting Us” section below.
          </p>
          <p>
          We will not discriminate against you for exercising any of these rights. Further information may be needed to verify your identity before exercising these rights, such as your email address or government issued ID. You may designate, in writing or through a power of attorney document, an authorized agent to make requests on your behalf. Before accepting such a request from an agent, we will require that the agent provide proof you have authorized them to act on your behalf, and we may need you to verify your identity directly with us. If we deny your request, you may appeal our decision by contacting us through the methods provided on our domain.
          </p>
        </li>

        <li>
          <h2 className="mb-2">Regional Privacy Disclosures</h2>
          <p>
            <span className="font-bold">Residents of California. </span>
            Throughout this Policy, we discuss in detail the specific pieces of personal information and sensitive personal information we collect, the sources of that information, and how we disclose it. Under the California Consumer Privacy Act (“CCPA”), we also have to provide you with the “categories” of personal information and sensitive personal information we collect and disclose for business or commercial purposes (as “categories” are defined by the CCPA).
          </p>
          <p>
          The categories of personal information are: identifiers, financial information (such as payment information), commercial or transactional information, internet or other electronic network activity information, general geolocation data, audio or video information (such as pictures and videos you submit), other information about you that you may provide to us voluntarily, such as through User Content, other information that may identify you, and inferences drawn from the information we collect.
          </p>
          <p>
          We also collect the following categories of “sensitive personal information,” as defined under the CCPA: (i) account log-in and password or other credentials that allow access to your account; and (ii) other sensitive personal information you may voluntarily provide to us in your capacity as an individual user of the Services (e.g. if you voluntarily post User Content revealing your race, religion, or sexual orientation).
          </p>
          <p>
          We process the categories of personal information identified above for the purposes as described in more detail in Section 2 of this Privacy Policy above.
          </p>
          <p>
          We collect the categories of personal information identified above from the following sources: (1) directly from you; (2) through your use of the Services; and (3) other parties such as other users and through unaffiliated parties.
          </p>
          <p>
          We describe our information disclosure practices in more detail in Section 3 of this Privacy Policy above.
          </p>
          <p className="italic">
          “Sale” and “sharing” of personal information
          </p>
          <p>
          The CCPA sets forth certain obligations for businesses that “sell” or “share” personal information. Where we refer to “sell” or “share” (or their variants) in quotes, we are referring to those terms as uniquely defined in the CCPA.
          </p>
          <p>
          We may use third-party analytics services and online advertising services that may result in the “sharing” of online identifiers (e.g., cookie data, IP addresses, device identifiers, general location information, and usage information) with advertising partners to advertise the Services on other websites. If you or your authorized agent would like to opt out of our “sharing” of your information for such purposes, you may do so by contacting us via methods provided on our website. We do not knowingly “sell” or “share” the personal information of children under 16.
          </p>
          <p className="italic">
            Do-Not-Track disclosure
          </p>
          <p>
          We do not respond to browser-initiated Do Not Track signals, as the Internet industry is currently still working on Do Not Track standards, implementations, and solutions.
          </p>
          <p>
          Retention of your personal information
          </p>
          <p>
          Please see the Data Retention section below.
          </p>
          <p className="italic">
            CCPA rights
          </p>
          <p>
          California residents can make requests about their personal information as described as set forth in Rights to Information About You above. The CCPA also allows you to limit the use or disclosure of your “sensitive personal information” if your sensitive personal information is used for certain purposes. Please note that we do not use or disclose sensitive personal information other than for purposes for which you cannot opt out under the CCPA.
          </p>
          <p>
            <span className="font-bold">Residents of Nevada. </span>
            If you are a resident of Nevada, you have the right to opt-out of the sale of certain personal information to unaffiliated parties. You can exercise this right by contacting us as described in the “Contact Us” section below with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address. We do not currently sell your personal information as sales are defined in Nevada Revised Statutes Chapter 603A.
          </p>
          <p>
            <span className="font-bold">Residents of the European Economic Area and United Kingdom. </span>
            Digiternity.AI is considered the “data controller” of the “personal data” (as defined under the General Data Protection Regulation) we handle under this Policy. In other words, Digiternity.AI is responsible for deciding how to collect, use, and disclose personal data, subject to applicable law. The laws of the European Economic Area and the United Kingdom require data controllers to tell you about the legal ground that they rely on for using, sharing, or disclosing your personal data. To the extent those laws apply, our legal grounds are as follows:
          </p>
          <ul>
            <li>
            	Contractual Commitments: We may use, share, or disclose personal data to honor our contractual commitments to you. For example, we will process your personal data to comply with our agreements with you, and to honor our commitments in any contracts that we have with you.
            </li>
            <li>
            	With Your Consent: Where required by law, and in some other cases, we use, share, or disclose personal data on the basis of your consent.
            </li>
            <li>
            	Legitimate Interests: In many cases, we use, share, or disclose personal data on the ground that it furthers our legitimate business interests in ways that are not overridden by the interests or fundamental rights and freedoms of the affected individuals, such as customer service, certain promotional activities, analyzing and improving our business, providing security for the Services, preventing fraud, and managing legal issues.
            </li>
            <li>
            	Legal Compliance: We need to use and disclose personal data in certain ways to comply with our legal obligations.
            </li>
          </ul>
        </li>

        <li>
          <h2 className="mb-2">Third-party Websites and Materials</h2>
          <p>
          By using our Services, you understand that your personal information may be processed and stored on servers, and transferred to third parties, outside your country of residence.
          </p>
          <p>
          The personal information that you provide to us and that we collect from you will be transferred to, stored at, or processed in, countries outside your country of residence, such as to the United States or Japan. Your personal information is also processed by staff operating in Japan, the US, India, and China who work for us or one of our third-party service providers or partners. We process the personal information that you provide to us in countries outside your country of residence in order to provide our Services, perform our contract with you, and provide our website’s functionality. We also use standard contractual clauses (SCCs) as relevant for certain transfers of personal information to third countries, unless the transfer is to a country that has been determined to provide an adequate level of protection for individuals’ rights and freedoms for their personal information.
          </p>
          <p>
          As described in section 1 “Introduction”, Digiternity Inc. is the relevant data controller for the processing of your information, and we are a domestic corporation established in the United States.
          </p>
        </li>

        <li>
          <h2 className="mb-2">Consent to Transfer</h2>
          <p>
          Our services are global and your information may be stored and processed in the United States and other countries outside the United States that may have data protection laws that differ from the laws in your country, and data may be accessible to law enforcement and national security authorities under certain circumstances. By using the Services, or providing us with any information, you consent to the collection, processing, maintenance, and transfer of such information in and to the United States and other applicable countries in which the privacy laws may not be as comprehensive as, or equivalent to, those in the country where you reside and/or are a citizen.
          </p>
        </li>

        <li>
          <h2 className="mb-2">Use of Digiternity.AI by Minors</h2>
          <p>
          Our Services are not intended for individuals under the age of 18. If we discover that minors under the age of 18 are using our platform and our Services, we will promptly block their access and delete their account. If you have reason to believe that a minor under the age of 18 has provided personal information to us through the Services, please contact us, and we will endeavor to delete that information from our databases. We may, where permitted by law, retain certain information internally for purposes described in this Policy.
          </p>
        </li>

        <li>
          <h2 className="mb-2">Changes to This Privacy Policy</h2>
          <p>
          We may change this Privacy Policy from time to time, in which case we will update the “Effective” date at the top of this Privacy Policy and post the updated Privacy Policy  on our Site. If we make material changes to the way in which we use personal information collected about you, we will use commercially reasonable efforts to notify you and take additional steps as required by applicable law. We recommend that you check this page regularly to keep up-to-date. If you do not agree to any updates to this Privacy Policy, please do not use or access any of the Services.
          </p>
        </li>

        <li>
          <h2 className="mb-2">Contacting Us</h2>
          <p>
            If you have any questions or suggestions about our Privacy Policy, please contact us at { " " }
            <a href="mailto:privacy@digiternity.ai">privacy@digiternity.ai</a>.
          </p>
        </li>
      </ol>
    </section>
  </main>
);
