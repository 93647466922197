

const AboutPage = () => {
    return (
        <main className="relative isolate bg-background">
            <section className="container prose prose-zinc relative max-w-4xl py-32 dark:prose-invert">
                <h2 className='text-4xl font-bold tracking-tight'>
                    About Us
                </h2>
                <div>
                    <p>
                        We are a team of creators, builders, and Web3 evangelists, united across the globe by a shared conviction and vision - AGI is on the horizon, a new era of ubiquitous human-AI collaboration and interaction is upon us, and AI human cloning will transform daily lives and offer a pathway to digital eternity for every individual and the entire humanity.
                    </p>
                    <p>
                        The idea behind everything users experience today at digiternity.ai started on a starry night in Southern California, when Maggie, co-founder of Digiternity.AI, deeply immersed in a movie she had just watched, just could not step back into reality long after the credits had rolled, she wished the fiction she was completely immersed in could continue forever.
                    </p>
                    <p>
                        A fiction had to end at some point, Maggie of course understood that. But the urge lingered, sparking a thought that ended up planting the seed for the birth of Digiternity.AI  – Maggie kept thinking, the movie can’t continue, but it would be quite a consolation if she could instead talk to the actors that portrayed the characters and told the story, and of course, that is a far-fetched wish too. After all, for the average person, an in person interaction with movie stars is just virtually impossible (no pun intended).
                    </p>
                    <p>
                        Not long after, on a sleepless night, as ideas swirled in her mind, Maggie reached out to Joe, a longtime friend and a serial entrepreneur. Her question was simple and straightforward: "Could you develop a digital avatar of a movie character - realistic enough that talking to the avatar would feel like I'm actually talking to the character on a video call?"
                    </p>
                    <p>
                        And from that call, Digiternity.AI’s thrilling and courageous journey began!
                    </p>
                    <p>
                        What began as a simple, albeit improbable, wish to converse with fictional movie characters has since evolved into something far more profound. Today, digiternity.ai’s endeavor is much beyond creating hyper-realistic AI avatars – we have set out to build a web3 AI avatar platform, community, and universe that empowers individuals to create their own digital existence through AI avatars. These avatars are more than just digital entertainers or companions, their unlimited possibilities include personal assistants in work and life, friends, a cyber workforce, a virtual community, and ultimately, a digital legacy and a digital eternity that will forever be preserved in our never ending and ever evolving humanity.
                    </p>
                </div>
                <h4 className="mt-12 text-xl font-bold tracking-tight">
                    Our vision is founded on three core values:
                </h4>
                <ul className="mt-4 list-none">
                    <li>
                        <h3 className="font-bold text-lg pl-[20px]">1. Digital Eternity – </h3>
                        <p>
                            AI Cloning offers every individual human being and the collective humankind the ultimate and most intuitive approach to achieve legacy preservation and digital eternity for human existence and civilization. Everyone can be a creator of a Digiternity.AI avatar – a hyper-realistic digital human that replicates the subject entity’s look, voice, speech, thinking patterns, and personalities, and preserves the subject entity’s knowledge, thoughts, experiences, and lifelong stories, forever, for the future generations, and for the eternity.
                        </p>
                    </li>
                    <li>
                        <h3 className="font-bold text-lg pl-[20px]">2.	AI Cloning and Digital Avatar Virtual Networking and Communities will Enrich and Transform Daily Lives with Endless Possibilities – </h3>
                        <p>
                        Real time lifelike video chats with ultra-realistic AI avatars enables every individual to overcome barriers in the physical world to connect with the digital replica of any real life or fictional characters they may have long wanted to have a one-on-one personal conversation but have not been able to due to limitations from time, space, and social boundaries in the real world. 
                        </p>
                        <p>
                        The possibilities of a new era of ubiquitous human-AI collaborations and interactions are virtually limitless – 
                        </p>
                        <ul className="list-disc list-inside">
                            <li>
                                AI avatars can be anyone, including celebrities such as artists, entertainers, and politicians, historical icons, KOLs and influencers, families, friends, fictional characters, amine idols, and even the creator themself. 
                            </li>
                            <li>
                                And the potentials in what AI avatars can do for humans are infinite – AI powered ultra-realistic avatars are the human beings of the cyber universe, they can provide entertainment as real life entertainers; they can offer companionship as families and friends; they can be trained into personal assistants both in professional contexts and for everyday life; they are subject matter experts, teachers, mentors, and consultants in providing on demand and top quality information, knowledge, advice, and consultation; they enable a new way of remembrance of and connection with the departed loved ones via AI resurrections, and creating an eternal digital existence of the legacies for the departed. 
                            </li>
                        </ul>
                    </li>                        
                    <li>
                        <h3 className="font-bold text-lg pl-[20px]">3.	Facilitating Advancement toward the Arrival of AGI – </h3>
                        <p>
                        The arrival of AGI is inevitable and could happen much sooner than many may think and believe, and AI cloning and AI avatar networking communities will help prepare humanity for, and facilitate a smooth and proactive transition into, the rapidly approaching AGI era.
                        </p>
                    </li>
                </ul>
            </section>
        </main>
    )
}

export {
    AboutPage
};