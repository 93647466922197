

const TermsOfServicePage = () => {
    return (
        <main className="relative isolate bg-background">
            <section className="bg-secondary-accent py-24 sm:py-32">
                <div className="container">
                    <h2 className='text-4xl font-bold tracking-tight'>
                        Terms of Service
                    </h2>
                </div>
            </section>
        </main>
    )
}

export {
    TermsOfServicePage
};