import { motion } from "framer-motion";


const UseCaseItem = ({ title, content }: { title: string, content: string }) => {
    return (
        <div className="border-[1px] light:border-black dark:border-white py-3 px-4 rounded-3xl w-[350px] h-[155px] shadow-md">
            <p className="font-bold text-center">
                {title}
            </p>
            <p className="mt-3">
                {content}
            </p>
        </div>
    )
}

const useCaseContents = [
    {
        title: "Video Chat with Your Favorite Celebrities",
        content: "No need to chase your favorite stars around the country just to see them from a distance. Digiternity’s celebrity avatars allow anyone to video chat with the celebrities they've always wanted to meet and talk to but never could."
    },
    {
        title: "On-Demand Social Media Influencers",
        content: "In addition to conventional interactions on traditional media platforms, AI avatars provide revolutionary new ways of influencer-fan engagement. Fans can video chat with influencers 24/7 about any topics of their interest."
    },
    {
        title: "Bring Your Virtual Anime Characters to Life",
        content: "Beloved anime characters can now exist in the real world. You bring them to life, and with our holographic box, they can become your ultimate J.A.R.V.I.S. right next to you. "
    },
    {
        title: "Companionship for Elderly or Children",
        content: "Busy schedule? No problem. Create a digital clone of yourself to read bedtime stories, to teach languages and cultures or chat with the elderly about life updates."
    },
    {
        title: "Influencers’ Dream Collaboration",
        content: "Live stream with your favorite cartoon characters and celebrities? We make real-time user-generated AI avatar interactions possible -  stable and seamless streaming. "
    },
    {
        title: "Creators Lead with AI-Generated Videos",
        content: "Want to stay ahead with AI-generated videos and idols? On our decentralized platform, you will own your creations for life and receive the highest revenue share."
    },
    {
        title: "Remembering Departed Loved Ones",
        content: "AI resurrection, or digital necromancy, offers new ways to remember and reconnect with our loved ones, revisit precious memories, and create digital legacies of the departed that will live on in digital eternity."
    },
    {
        title: "Web3 Investors’ Safe Haven",
        content: "Tired of the chaos in meme coins? Invest in our real-person avatar-based NFTs and enjoy steady earnings from the avatars."
    },
    {
        title: "UBI for Graduating Students",
        content: "Concerned about job prospects? We understand the impact of AI. 20% of earnings will be distributed as UBI to eligible NFT holders monthly."
    },
]

const UseCases = () => {
    
    return (
        <section className="relative bg-secondary-accent py-24 sm:py-32">
            <div className="container">
                <div className="text-center mb-12"> {/* Added this wrapper div with text-center */}
                    <h2 className='text-4xl font-bold tracking-tight'>
                        USE CASES
                    </h2>
                    <p className="mt-4 text-base leading-relaxed">
                        Everyone's Super Intelligence to Connect, Create, and Collect
                    </p>
                </div>
                <div className="mt-10 flex flex-wrap gap-5 justify-center items-start">
                    {
                        useCaseContents.map((item, idx) => (
                            <motion.div
                                key={idx}
                                viewport={{ once: true }}
                                initial={{ opacity: 0, x: -50 }}
                                whileInView={{ opacity: 1, x: 0, transition: { delay: idx * 0.1 } }}
                            >
                                <UseCaseItem {...item} />
                            </motion.div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
};

export { UseCases };
