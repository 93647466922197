/* eslint-disable lingui/no-unlocalized-strings */
import { t } from "@lingui/macro";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

type CheckoutData = {
  productName: string;
  price: number;
};

const handleClose = () => {
  window.close();
};

export const PaymentSuccessPage: React.FC = () => {
  const [checkoutData, setCheckoutData] = useState<CheckoutData | null>(null);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const data = searchParams.get("data");
    if (data) {
      try {
        setCheckoutData(JSON.parse(decodeURIComponent(data)));
      } catch (error) {
        console.error("Failed to parse checkout data:", error);
      }
    }
  }, [location]);

  return (
    <main className="relative isolate bg-background">
      <Helmet prioritizeSeoTags>
        <title>
          {t`Payment Successful`} - {t`Digiternity.AI`}
        </title>
        <meta
          name="description"
          content="Your payment has been successfully processed. Thank you for your purchase."
        />
      </Helmet>

      <section
        id="payment-success"
        className="container prose prose-zinc relative max-w-4xl py-32 dark:prose-invert"
      >
        <h1 className="mb-4">{t`Payment Successful`}</h1>

        <hr className="my-6" />

        {checkoutData ? (
          <div>
            <p className="mb-2 text-xl">{t`Thank you for your purchase of:`}</p>
            <p className="mb-4 text-2xl font-semibold">{checkoutData.productName}</p>
            <p className="text-lg">
              {t`Total paid:`} ${checkoutData.price.toFixed(2)}
            </p>
            <button
              className="mt-4 rounded bg-blue-500 px-4 py-2 font-bold text-white transition duration-300 ease-in-out hover:bg-blue-700"
              onClick={handleClose}
            >
              {t`Close`}
            </button>
          </div>
        ) : (
          <p>{t`Loading payment information...`}</p>
        )}
      </section>
    </main>
  );
};

export default PaymentSuccessPage;
