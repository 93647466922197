import React from "react";

const Paragraph = ({ children }: {children: React.ReactNode}) => (
    <p className="mt-4 text-base leading-relaxed">
        {children}
    </p>
);

const BoldTitle = ({ text }:{ text:string }) => (
    <span className="font-bold text-lg">
        {text}
    </span>
);

const Blockchain = () => {
    return (
        <section className="relative bg-background overflow-hidden min-h-screen flex flex-col">
            <div className="container flex-grow flex flex-col">
                <div className="flex flex-col lg:flex-row flex-grow">
                    <div className="lg:w-1/2 py-24 sm:py-32 pr-4 sm:pr-6 lg:pr-8 flex flex-col justify-center relative z-20">
                        <h2 className='text-4xl font-bold tracking-tight'>
                            WEB3 and NFT
                        </h2>
                        <Paragraph>
                            WEB 3.0 - Co-create a Decentralized Social Media Platform and Community.
                        </Paragraph>
    
                        <div className="mt-10">
                            <Paragraph>
                                <BoldTitle text="■ Personal Digital Wallets:" /> 
                                &nbsp;Every creator owns their digital wallet, managing all NFTs and cryptos created or invested in avatars.
                            </Paragraph>
    
                            <Paragraph>
                                <BoldTitle text="■ Transparent and Immutable Profit Sharing: " /> 
                                &nbsp;Real-time, on-chain profit distribution that is transparent and secure. Passive and perpetual income for stakeholders.
                            </Paragraph>
    
                            <Paragraph>
                                <BoldTitle text="■ Decentralized:" /> 
                                &nbsp;The platform is co-created and owned by its users, ensuring fairness and decentralization in income generation and profit sharing.
                            </Paragraph>
    
                            <Paragraph>
                                <BoldTitle text="■ Universal Basic Income:" /> 
                                &nbsp;Users with human verified avatar NFTs are eligible to earn a universal basic income through their NFTs.
                            </Paragraph>
    
                            <Paragraph>
                                <BoldTitle text="■ Empowerment through Technology: " /> 
                                &nbsp;Empower individuals to create, invest, and prosper in a decentralized digital ecosystem with human verified ID.
                            </Paragraph>
                        </div>
                    </div>
                    <div className="lg:w-1/2 relative h-[500px] lg:h-auto lg:absolute lg:right-0 lg:top-0 lg:bottom-0">
                        <video 
                            className="absolute top-0 right-0 w-full h-full object-cover"
                            autoPlay 
                            loop 
                            muted 
                            playsInline
                        >
                            <source src="/videos/web3.webm" type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                        {/* <div className="absolute top-0 left-0 bottom-0 w-28 bg-background transform -skew-x-6 origin-top-right z-10"></div> */}
                        <div className="absolute top-0 left-0 bottom-0 w-28 bg-background transform -skew-x-6 origin-top-right z-10 hidden lg:block"></div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export { Blockchain }