import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@reactive-resume/ui";



const Question1 = () => (
  <AccordionItem value="1">
    <AccordionTrigger className="text-left leading-relaxed">
      How do I create an avatar at Digiternity.AI?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        Creating avatars at Digiternity.AI is effortless and at zero cost. Simply go to the Creator Dashboard, read the instructions on input materials we need from you, prepare the input materials, and submit them through the Creator Dashboard. Currently, once you submit your input materials and your input materials have passed our input requirement review, your avatar should be ready to go online with one week. A complete self-serve avatar creation function will be available in the near future.
      </p>
    </AccordionContent>
  </AccordionItem>
);

const Question2 = () => (
  <AccordionItem value="2">
    <AccordionTrigger className="text-left leading-relaxed">
      Who can create avatars at Digiternity.AI?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        Our Terms of Service prohibit use of our platform and services in any way by anyone under the age of 18. There are no restrictions otherwise on who can create and post avatars at Digiternity.AI. For the time being though, we are in the beta testing phase and avatar creation is accommodated on an invitation-only basis.
      </p>
    </AccordionContent>
  </AccordionItem>
);

const Question3 = () => (
  <AccordionItem value="3">
    <AccordionTrigger className="text-left leading-relaxed">
      What are the benefits of being a Digiternity.AI creator?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        Be a Digiternity.AI creator has many attractive benefits:
      </p>
      <ul>
        <li>
          <span className="font-bold">Zero Cost:</span> Create an ultra-realistic avatar with a customized persona and enjoy continuous optimization, all at no monetary cost.
        </li>
        <li>
          <span className="font-bold">24/7 Interaction:</span> If you post your avatar for public access and your avatar successfully attracts other Digiternity.AI users to engage in voice or video chats, your avatar will be generating chat revenue, thus generating income for you the creator, around the clock,
        </li>
        <li>
          <span className="font-bold">Passive Monetization:</span> The revenue share for a creator is not just consistent, transparent, and around the clock, it is also entirely passive – the creator needs to do anything else, ever, once the avatar is created.
        </li>
        <li>
          <span className="font-bold">Highest Revenue Share in the Industry:</span> Our decentralized platform offers one of the highest revenue share for creators compared to other comparable platforms.
        </li>
        <li>
          <span className="font-bold">Dynamic, Self-Evolving Avatar:</span> No continuous ongoing maintenance or further enhancement effort needed from the creator, the avatar learns and evolves on their own as their chat with users increase in length, depth, and breadth. The more interactions with users, the more intelligent and knowledgeable the avatar will become, and the more comprehensive, sophisticated, unique, and realistic the avatar’s persona will become.
        </li>
        <li>
          <span className="font-bold">Decentralized Ownership:</span> Own your avatar, content, coins, and NFTs without the risk of account bans or restricted earnings.
        </li>
        <li>
          <span className="font-bold">AGI Development & UBI:</span> Be an early adopter and a trailblazing leader of the AI-human collaboration movement and facilitate the advancement toward the arrival of AGI. Digiternity.AI avatars themselves participate in the revenue share and receive 1% of the total revenue, and eligible creators will receive universal basic income generated by the avatars.
        </li>
      </ul>
    </AccordionContent>
  </AccordionItem>
);

const Question4 = () => (
  <AccordionItem value="4">
    <AccordionTrigger className="text-left leading-relaxed">
      How do I monetize as a creator at Digiternity.AI?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        Upon creation and public posting of an avatar, Digiternity.AI will automatically establish six Web3 wallets on the Digiternity blockchain for 6 revenue share stakeholders for that avatar’s lifetime revenue. The 6 stakeholders are: Copyright Owner, Creator, NFT Owner, Digiternity.AI, UBI Pool, and the AI Avatar itself (yes, the digital AI avatar gets a wallet and receives a share of the revenue as well).
      </p>
      <p>
        The current revenue share percentages are:
      </p>
      <ul >
        <li>
          Copyright Owner – 30%
        </li>
        <li>
          Creator – 20%
        </li>
        <li>
          NFT Owner – 20%
        </li>
        <li>
          Avatar – 1%
        </li>
        <li>
          UBI Pool – 20%
        </li>
        <li>
          Digiternity.AI – 9%
        </li>
      </ul>
      <p className="mt-3">
        The moment an avatar is created and also made publicly accessible, the creator will take ownership of the Creator Share of the revenue.
      </p>
      <p>
        If the subject entity of the avatar is the creator themself, the creator is also the avatar’s IP copyright owner, and will receive the Copyright Owner Share of the revenue.
      </p>
      <p>
        If a creator creates an avatar for someone else, such as a celebrity, a family member or a friend, or a copyrighted fictional character such as an anime idol or a movie character, then the avatar’s IP copyright belongs to the actual source human individual or the copyright holder of the original fictional entity. In this case, the actual copyright owner can at any time, upon completing a required verification protocol, claim their wallet, which has been automatically created and receiving all entitled revenue in accordance with the revenue share policy.
      </p>
      <p>
        And 1% of the revenue will go to the avatar themself, the avatar will own the wallet and have complete control over the funds.
      </p>
      <p>
        20% of the revenue is allocated to a UBI pool set up and managed by Digiternity.AI and eligible members of the Digiternity.AI community will receive UBI from this fund pool.
      </p>
      <p>For every avatar created and made public, an NFT is created and ownership of that NFT represents 20% equity of the avatar’s revenue. The creator has the ROFR (Right of First Refusal) with regards to purchasing the NFT. In the event the creator elects not to own the NFT, Digiternity.AI will own the NFT and receive the due share of revenue.</p>
    </AccordionContent>
  </AccordionItem>
);


const Question5 = () => (
  <AccordionItem value="5">
    <AccordionTrigger className="text-left leading-relaxed">
    What is an NFT? Why use NFTs, and what are the benefits of owning one?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
      An NFT, or non-fungible token, allows creators to tokenize their avatars. At Digiternity.AI, every avatar created and made publicly accessible will automatically have an NFT minted representing 20% equity of that avatar’s revenue. The creator has the preferential right to purchase the NFT and receive the NFT Owner Share of the avatar’s revenue. If the creator elects not to purchase the NFT, Digiternity.AI will become the owner of the NFT. An NFT owner can sell an NFT and thereby transferring the ownership of that NFT and the associated 20% share of the avatar's gross revenue to the new NFT owner. 
      </p>
      <p>
      Each NFT at digiterni.ai represents a real or anime-based digital human, recreated using advanced AI cloning technology to replicate physical appearance, voice, persona, and knowledge base - achieving a form of digital eternity for the subject entity. NFT holders receive 20% of all revenue generated by their AI avatar, providing a fully passive income stream. Additionally, verified NFT holders are eligible to receive a universal basic income, funded from 20% of the total revenue generated by all avatars on the platform - a groundbreaking initiative marks the first time in history that AI-generated earnings are distributed to everyday individuals. 
      </p>
    </AccordionContent>
  </AccordionItem>
);

const Question6 = () => (
  <AccordionItem value="6">
    <AccordionTrigger className="text-left leading-relaxed">
    What languages are supported?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
      Currently, the avatars at Digiternity.AI are set to support three languages – English, Japanese, and Mandarin Chinese. Each avatar’s default language is set to the native tongue of the avatar’s subject entity. On the upper left corner of the avatar video call screen, there is a drop down menu where a user can switch to any of the three language options provided to conduct the call. 
      </p>
    </AccordionContent>
  </AccordionItem>
);

const Question7 = () => (
  <AccordionItem value="7">
    <AccordionTrigger className="text-left leading-relaxed">
    Is group chat with multiple avatars possible?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
      We plan to add this feature in the near future.
      </p>
    </AccordionContent>
  </AccordionItem>
);


const Question8 = () => (
  <AccordionItem value="8">
    <AccordionTrigger className="text-left leading-relaxed">
    Can I trust the avatar’s responses in terms of factualness and accuracy?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
      While Digiternity.AI’s avatars can be impressively informative a lot of times and in many ways, the information outputted by the avatars can not and should never be taken as verified factual and/or accurate information. 
      </p>
    </AccordionContent>
  </AccordionItem>
);


const Question9 = () => (
  <AccordionItem value="9">
    <AccordionTrigger className="text-left leading-relaxed">
    Who can see the conversations I have with avatars?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
      Beyond Digiternity.AI’s data privacy and security personnels and our content mods who have access to all chat content for administrative purposes, chat content is only visible to and the records can only be accessed by the user(s) engaged in the relevant chat where that content is generated from. 
      </p>
    </AccordionContent>
  </AccordionItem>
);

const Question10 = () => (
  <AccordionItem value="10">
    <AccordionTrigger className="text-left leading-relaxed">
    Is there a transcript of the chat available for access at a later time?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
      Yes, all text chat history, and transcripts of video and voice calls completed are available for access later.
      </p>
    </AccordionContent>
  </AccordionItem>
);

const Question11 = () => (
  <AccordionItem value="11">
    <AccordionTrigger className="text-left leading-relaxed">
    What is the company’s stance on NSFW content?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
      Digiternity.AI does not and will not support the use of our platform and any of our services for vulgar, obscene, pornographic content, or anything fitting the commonly understood and accepted NSFW content descriptions.
      </p>
    </AccordionContent>
  </AccordionItem>
);


const Question12 = () => (
  <AccordionItem value="12">
    <AccordionTrigger className="text-left leading-relaxed">
      The first time you sign in to access our platform, you will be asked to register a new account. 
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
        There are three ways to register – 
      </p>
      <ol>
        <li>
          You can use one of the following three social media SSO (single sign-on) services - Google, Facebook, and Apple, to sign in and create your account for the first time. You will simply select the SSO you used for all subsequent sign ins.
        </li>
        <li>
          You can choose to use the email + one time passcode approach for your first sign in, creation of your account, and all subsequent sign ins.
        </li>
        <li>
          You can also create a Digiternity.AI account by connecting a web3 wallet. Simply select connection with that same wallet for all subsequent sign ins.
        </li>
      </ol>
    </AccordionContent>
  </AccordionItem>
);


const Question13 = () => (
  <AccordionItem value="13">
    <AccordionTrigger className="text-left leading-relaxed">
    What is a Digiternity.AI wallet? 
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
      As soon your Digiternity.AI account is created, a Digiternity.AI wallet will be created for your account. As Digiternity.AI is a blockchain based platform, this wallet is a web3 wallet, and will be used to store your crypto currencies that you have put into this wallet, your digital assets such as Digiternity.AI avatar NFTs, and Digiternity.AI issued vendor credits. 
      </p>
      <p>
      USDC is the designated and only accepted currency for transactions within the Digiternity.AI community. All prices at Digiternity.AI are denominated in USDC. You can directly transfer USDC into your Digiternity.AI wallet from your other web3 wallets. You can also deposit funds into your Digiternity.AI wallet using a fiat currency credit card, in which case, you will designate an amount in USDC to deposit into your Digiternity.AI wallet, and the equivalent amount in the fiat currency you use will be automatically calculated based on the conversion rate at the time of the conversion and charged to your fiat currency credit card.
      </p>
    </AccordionContent>
  </AccordionItem>
);

const Question14 = () => (
  <AccordionItem value="14">
    <AccordionTrigger className="text-left leading-relaxed">
      How do I transfer USDC to my Digiternity.AI wallet?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
      Digiternity Diamonds (the “Diamonds”) are the official currency in the Digiternity.AI universe and on all Digiternity.AI operated platforms. All prices at Digiternity.AI are denominated and expressed in Diamonds. And Digitenity Diamonds are set at 1 Diamond equal 1 USDC conversion ratio. 
      </p>
      <p>
      Funds you deposit into your Digiternity.AI wallet using fiat currency will be automatically converted to Diamonds based on the real time conversion ratio between USDC and the fiat currency you use.
      </p>
      <p>
      If you deposit USDC into your Digiternity.AI wallet, they will remain as USDC in your wallet, and will be deducted as you make purchases at the 1 Diamond = 1 USDC conversion rate.
      </p>
      <p>
      Vendor credits you receive from Digiternity.Ai will be in Diamonds as well. 
      </p>
    </AccordionContent>
  </AccordionItem>
);

const Question15 = () => (
  <AccordionItem value="15">
    <AccordionTrigger className="text-left leading-relaxed">
    How does Digiternity.AI ensure privacy and security?
    </AccordionTrigger>
    <AccordionContent className="prose max-w-none dark:prose-invert">
      <p>
      At Digiternity.AI, privacy and security of our creators and users and all members of the Digiternity.AI community are our very top priorities. Our platform employs decentralized Web3 technology for enhanced security while providing transparency inherent with our blockchain mechanism. All interactions are encrypted, ensuring the confidentiality of all interactions with the avatars. We leverage advanced blockchain technology to secure user data and transactions, granting all stakeholders full control over their digital assets and personal information.
      </p>
    </AccordionContent>
  </AccordionItem>
);


const FaqPage = () => {
  return (
    <main className="relative isolate bg-background">
      <section className="bg-secondary-accent py-24 sm:py-32">
        <div className="container">
          <div className="space-y-6">
            <h2 className="text-4xl font-bold">Frequently Asked Questions</h2>
            <p className="text-base leading-loose">
              Here are some questions we often get asked about Digiternity.AI.
            </p>
          </div>
          <div className="my-10">
            <h2 className="text-2xl font-bold text-center">Avatar Creation -</h2>
            <Accordion collapsible type="single">
              <Question1 />
              <Question2 />
              <Question3 />
              <Question4 />
              <Question5 />
            </Accordion>
          </div>
          <div className="my-10">
            <h2 className="text-2xl font-bold text-center">Avatar Chat -</h2>
            <Accordion collapsible type="single">
              <Question6 />
              <Question7 />
              <Question8 />
              <Question9 />
              <Question10 />
              <Question11 />
            </Accordion>
          </div>

          <div className="my-10"> 
            <h2 className="text-2xl font-bold text-center">Account Registration, Sign in, and Transaction - </h2>
            <Accordion collapsible type="single">
              <Question12 />
              <Question13 />
              <Question14 />
            </Accordion>
          </div>
          <div className="my-10">
            <h2 className="text-2xl font-bold text-center">Privacy and Security Related - </h2>
            <Accordion collapsible type="single">
              <Question15 />
            </Accordion>
          </div>
        </div>
      </section>
    </main>
  )
}

export {
  FaqPage
};