import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import { HomeLayout } from "../pages/home/layout";
import { PrivacyPolicyPage } from "../pages/home/privacy-policy/page";
import { HomePage } from "../pages/home/page";
import { PaymentSuccessPage } from "../pages/home/payment/page";
import { Providers } from "../providers";
import { AboutPage } from "../pages/home/about";
import { CareersPage } from "../pages/home/careers";
import { FaqPage } from "../pages/home/faq";
import { TermsOfServicePage } from "../pages/home/terms";
import { WhitePaperPage } from "../pages/home/whitepaper";

export const routes = createRoutesFromElements(
  <Route element={<Providers />}>
    <Route element={<HomeLayout />}>
      <Route path="/" element={<HomePage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/payment/success" element={<PaymentSuccessPage />} />
      <Route path="/payment/cancel" element={<PaymentSuccessPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/careers" element={<CareersPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/terms" element={<TermsOfServicePage />} />
      <Route path="/whitepaper" element={<WhitePaperPage />} />
    </Route>
  </Route>,
);

export const router = createBrowserRouter(routes);
